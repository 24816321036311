import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
	return (
		<GoogleReCaptchaProvider reCaptchaKey="6LfWAxAcAAAAAAOGyrgJoyai5h1GAGbVv3Er5buw">
			{element}
		</GoogleReCaptchaProvider>
	);
};
